import { css } from 'styled-components';
import { mq } from '@ccg/core';

const getTextColumns = props => {
  if (props.theme.contentType === 'advertising') ;

  return css(["grid-column:1 / span 6;", "{grid-column:3 / span 8;}"], mq('m'));
};

export { getTextColumns };
