import styled$1 from 'styled-components';

const StyledSlider = styled$1.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  flex: 1 1 auto;

  .slider__slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
    text-align: center;

    img {
      width: 80%;
      height: 80%;
      margin: 10% auto;
      user-select: none;
      pointer-events: none;
      object-fit: contain;
    }
  }
`;

export default StyledSlider;
