import styled$1, { css } from 'styled-components';
import { space, color, getProperty, mq } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledFilter = styled$1.aside`
  position: relative;
  margin: ${space(20)} 0;
  background: ${color('light')};

  .filter__selected {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: ${space(2)};

    ${mq('m')} {
      align-items: center;
      flex-direction: row;
    }

    button {
      display: inline-flex;
      align-items: center;
      margin-right: ${space(6)};
      color: ${color('bright')};
      padding: ${space(2)} 0;

      &:focus,
      &:hover {
        outline: none;
        color: ${color('highlight01')};

        svg {
          fill: ${color('highlight01')};
        }
      }

      ${mq('m')} {
        padding: 0;
      }
    }
  }

  .filter__selected-title {
    color: ${color('bright')};
    margin-right: ${space(6)};
  }

  ${({
  hasSingleGroup
}) => hasSingleGroup ? css([".filter__values{", ";display:none;width:100%;button{position:relative;text-align:left;display:inline-block;margin-bottom:", ";margin-right:", ";padding-bottom:", ";&:after{content:'';position:absolute;bottom:0;left:0;width:45%;height:2px;background:#c4c4c4;transition:all 200ms ease-in-out;}&[aria-pressed='true'],&:focus{outline:none;color:", ";}&[aria-pressed='true']{&:after{width:100%;background:", ";}}", "{margin-right:", ";}", "{margin-right:", ";}}}.filter__values[aria-hidden='false']{display:block;}"], getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
}), space(6), space(7), space(2), color('bright'), color('bright'), mq('m'), space(15), mq('l'), space(18)) : css([".filter__values{grid-column:1 / span 6;display:none;button{", ";position:relative;text-align:left;display:block;padding:", " 0;padding-right:", ";&:hover,&:focus{outline:none;color:", ";}&[aria-pressed='true']{outline:none;color:", ";}", "{padding:", " 0;}}}.filter__values[aria-hidden='false']{", ";}"], getProperty('grid-column', {
  default: 'span 6',
  m: 'span 2'
}), space(3), space(2), color('highlight01'), color('bright'), mq('m'), space(4), getGrid({
  default: 6
}, false))};

  .filter__groups {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    button {
      position: relative;
      grid-column: span 1;
      display: inline-flex;
      align-items: center;
      margin: 0;
      margin-bottom: ${space(4)};
      margin-right: ${space(7)};
      padding-bottom: ${space(2)};

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 45%;
        height: 2px;
        background: #c4c4c4;
        transition: all 200ms ease-in-out;
      }

      &[aria-pressed='true'],
      &:focus {
        outline: none;
        color: ${color('bright')};
      }

      &[aria-pressed='true'] {
        &:after {
          width: 100%;
          background: ${color('bright')};
        }
      }

      ${mq('m')} {
        margin-right: ${space(15)};
      }
    }
  }
`;

export default StyledFilter;
