import styled$1, { css } from 'styled-components';
import { getProperty, space, colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTransition } from '../../styles/transitions.js';

/* eslint-disable no-irregular-whitespace */
const StyledJobTeaser = styled$1.div`
  width: 100%;
  position: relative;
  ${getTransition}
  ${getProperty('grid-column-end', {
  default: 'span 6'
})};

  .jobTeaser__link {
    position: relative;
    padding: ${space(10)} ${space(5)};
    color: ${colorSet('colorSecondary')};
    display: flex;

    ${mq('m')} {
      min-height: ${space(92)};
      margin-right: 10%;
    }

    ${mq('l')} {
      min-height: ${space(116)};
      margin-right: 17%;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      background: ${colorSet('accent')};
      width: 100%;
    }
  }

  .jobTeaser__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    h2 {
      ${mq('m')} {
        ${getTypography.subheadline};
      }

      ${mq('l')} {
        ${getTypography.intro};
      }
    }
  }

  .jobTeaser__link-text {
    position: relative;
    display: inline-block;
    align-self: flex-start;
    margin-top: auto;
    text-transform: uppercase;
    letter-spacing: ${space(0.5)};
    transition: all 0.1s ease-in-out;
    padding-bottom: ${space(1)};

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: ${colorSet('colorSecondary')};
      transition: transform 0.1s ease-in-out;
    }
  }

  .jobTeaser__locations {
    margin: ${space(3)} 0 ${space(4)} 0;
  }

  &:hover .jobTeaser__link-text:after {
    transform: translateY(${space(-0.5)});
  }

  ${({
  isExternal
}) => isExternal && css([".jobTeaser__link:before{background:", ";}"], colorSet('backgroundSecondary'))}
`;

export default StyledJobTeaser;
