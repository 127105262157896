const typography = {
  scale: {
    ratio: [1.43, 1.43],
    base: [16, 17],
    width: [375, 980],
    breakpoints: 4,
    corrections: {}
  }
};
const fonts = {
  default: {
    family: 'Helvetica Neue, sans-serif'
  },
  accent: {
    family: '"Playfair Display", serif'
  },
  accentItalic: {
    family: '"Playfair Display Italic", serif'
  }
};

export { fonts, typography };
