import styled$1, { css } from 'styled-components';
import { mq, colorSet, color, space } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getOuterGridContainer, getInnerGridContainer, getGrid } from '../Grid/Grid.js';

const StyledNavigation = styled$1.div`
  position: relative;
  width: 100%;

  .navigation__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    ${getOuterGridContainer()}
    justify-content: left;

    ${mq('l')} {
      width: 100%;
      background: ${colorSet('backgroundSecondary')};
      position: relative;
      z-index: 2;

      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 52px;
        background: ${colorSet('background')};
      }
    }
  }

  .navigation__inner {
    ${getInnerGridContainer()}
    position: relative;
    width: 100%;
  }

  .navigation__list {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    color: ${color('light')};
    background: ${colorSet('backgroundSecondary')};
    overflow-y: ${props => props.isOpen ? 'scroll' : 'hidden'};
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    ${getGrid()};

    ${mq('l', 'max')} {
      margin-right: 0;
      margin-left: 0;
    }

    ${mq('l')} {
      position: relative;
      padding: 0;
      height: auto;
      width: auto;
      background: transparent;
      color: ${colorSet('color')};
      overflow: visible;
      z-index: 1;
    }
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
  }

    li {
    display: block;
    list-style-type: none;
  }


  .navigation__list-wrapper {
    margin-bottom: ${space(25)};
    margin-top: ${space(21)};
    grid-column: 2 / span 5;
    grid-row-start: 1;

    ${mq('l')} {
      margin-top: 0;
      margin-bottom: 0;
      grid-column: 3 / span 8;
    }
  }

  .navigation__mainlist  {
    display: block;
    max-width: initial;

    ${mq('l')} {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    ${props => !props.activePrimaryNavItem && css(["", "{display:none;}"], mq('l', 'max'))}
  }

  .navigation__mainlist > li {
    svg {
      display: none;
    }

    ${mq('l', 'max')} {  
      display: none;

      svg {
        display: inline;
        margin-left: ${space(3)};   
        fill: ${colorSet('accent')};
      }
     
      ${props => props.activePrimaryNavItem && css(["&[data-primary-label=", "]{display:block;}"], props.activePrimaryNavItem.replace(/\s/g, ''))}
      }
    }

  .navigation__mainlist--mobile {
    display: none;

    > li:last-child {
      padding-top: ${space(8)};
      border-top: 1px solid ${colorSet('accent')};
      
      a {
        ${getTypography.subline};
      }
    }
    
    ${props => !props.activePrimaryNavItem && css(["", "{display:block;}"], mq('l', 'max'))}
  }

  .navigation__mainlist--mobile,
  .navigation__mainlist {
    li {
      margin: 0 0 ${space(8)};

      ${mq('l')} {
        display: inline-block;
        margin: 0;
      }

      a, button {
        display: inline-block;
        padding: ${space(4)};
        margin-left: -${space(4)};
      
        ${mq('l', 'max')} {
          ${getTypography.subheadline};
        }

        ${mq('l')} {
          padding: ${space(3)} ${space(8)};

          &:first-child {
            margin-right: -${space(8)};
          }
        }

        span {
          white-space: nowrap;
        }
      }

      button:hover {
        color: ${colorSet('accent')};
      }

      a:hover {
        color: ${colorSet('accent')};

        ${mq('l')} {
          & + .navigation__sublist .navigation__sublist--container {
            max-height: 320px; /* 6 nav items */
          }
        }
      }

      a.is-active { 
        & > span {
          color: ${colorSet('accent')};
        }

        &:hover {
          background: transparent;
          color: inherit;
        }
      }
    }
  }

  .navigation__sublist {
    margin-top: ${space(8)};

    ${mq('l')} {
      margin-top: 0;

      &:hover .navigation__sublist--container, 
      &:focus-within .navigation__sublist--container { 
        max-height: 320px; /* 6 nav items */
      }
    }     
  }

  .navigation__sublist--container {
     ${mq('l')} {
      overflow: hidden;

      /* to hide sublist on default */
      max-height: 0;
      transition: max-height 400ms ease-in-out 200ms;

      /* increase width cause sublinks might be longer than primary link */
      width: 300%;
    }
  }

  .navigation__sublist--inner {
    li a {
    ${mq('l', 'max')} {
        ${getTypography.subline};
      }
    }

    ${mq('l')} {
      display: flex;
      flex-direction: column;

      li {
        color: ${colorSet('background')};
        position: relative;
        height: 52px;
        white-space: nowrap;
      }

      a {
        position: absolute; 
        top: 0;
        left: 0;
      }
    }
  }

  .navigation__button--primary {
    color: ${colorSet('background')};
  }

  .navigation__button--active {
    color: ${colorSet('accent')};
  }

  .navigation__button--back {
    display: none;
    opacity: 0;
    position: absolute;
    top: ${space(10)};
    left: ${space(5)};
    padding: ${space(2)};
    ${getTypography.copy}
    color: ${colorSet('background')};
    align-items: center;
    transition: opacity 400ms ease-in-out 300ms;

    svg {
      transform: rotateY(180deg); 
      margin-right: ${space(3)};    
      fill: ${colorSet('accent')};
    }
    
    ${props => props.activePrimaryNavItem && css(["", "{opacity:1;display:flex;}"], mq('l', 'max'))}
  }

  .navigation__button--mobile {
    padding: ${space(1)};
    margin-right: ${props => props.isOpen ? '15px' : '0'};
    color: ${props => props.isOpen ? color('light') : 'inherit'};
    position: absolute;
    top: 0;
    right: 0; 
    z-index: 99;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mq('l')} {
      display: none;
    }

    svg {
      pointer-events:none;
    }
  }

  .navigation__button--open {
    width: 34px;
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > span {
      width: 100%;
      height: 2px;
      background: ${colorSet('accent')};
      pointer-events:none;
    }
  }

  .navigation__button--close {
    svg {
      fill: ${colorSet('accent')};
    }
  }

  .navigation__logo--container {
    position: absolute;
    z-index: 99;
    grid-column: 1 / span 5;

    ${mq('l')} {
      position: relative;
      grid-column: 1 / span 2;
    }
  }

  .navigation__logo {
    display: block;
    padding: ${space(4)} ${space(4)} ${space(2)} ${space(6)};
    line-height: 0;

    ${mq('l')} {
      padding: ${space(3)};
      margin-top: 2px;
      margin-left: -${space(3)};

    }

    svg {
      fill: ${props => props.isOpen ? colorSet('background') : colorSet('accent')};

      ${mq('l')} {
        fill: ${colorSet('accent')};
      }
    }


  }
`;

export default StyledNavigation;
