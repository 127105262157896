const colors = {
  bright: '#ff005f',
  light: '#fff',
  dark: '#000',
  neutral: '#EEEEEE',
  highlight01: '#3c009c',
  highlight02: '#7bd1e6',
  highlight03: '#0000fe',
  highlight04: '#ffcd64'
};
const colorSets = {
  neutral: {
    color: colors.dark,
    colorSecondary: colors.light,
    background: colors.light,
    backgroundSecondary: colors.highlight01,
    accent: colors.bright,
    neutral: colors.neutral
  }
};

export { colorSets, colors };
