import { css } from 'styled-components';
import { getProperty } from '@ccg/core';

const getCaptionAlignment = ({
  variant,
  inline
}) => {
  let m = inline ? '4 / span 4' : '7 / span 6';
  let l = inline ? '5 / span 4' : '8 / span 5';

  if (variant === 'm') {
    m = inline ? '3 / span 8' : '7 / span 6';
    l = inline ? '3 / span 8' : '8 / span 5';
  } else if (variant === 'l') {
    m = inline ? '3 / span 8' : '7 / span 6';
    l = inline ? '3 / span 8' : '8 / span 5';
  } else if (variant === 'xl') {
    m = inline ? '3 / span 8' : '7 / span 6';
    l = inline ? '3 / span 8' : '8 / span 5';
  }

  return css(["", " ", ";"], getProperty('grid-row', {
    default: 2,
    m: inline ? 2 : 1
  }), getProperty('grid-column', {
    default: '1 / span 6',
    m,
    l
  }));
};
const getImageAlignment = ({
  variant,
  inline
}) => {
  let m = inline ? '4 / span 4' : '3 / span 4';
  let l = inline ? '5 / span 4' : '4 / span 4';

  if (variant === 'm') {
    m = inline ? '3 / span 8' : '1 / span 6';
    l = inline ? '3 / span 8' : '2 / span 6';
  } else if (variant === 'l') {
    m = inline ? '2 / span 10' : '1 / span 6';
    l = inline ? '2 / span 10' : '1 / span 7';
  } else if (variant === 'xl') {
    m = inline ? '1 / span 12' : '1 / span 6';
    l = inline ? '1 / span 12' : '1 / span 7';
  }

  return css(["grid-row:1;", ";"], getProperty('grid-column', {
    default: '1 / span 6',
    m,
    l
  }));
};

export { getCaptionAlignment, getImageAlignment };
