import styled$1 from 'styled-components';
import { space, colorSet, mq } from '@ccg/core';
import { getGrid } from '../Grid/Grid.js';

const StyledDownloads = styled$1.div`
  ${getGrid()}
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};

  .downloads__wrapper {
    background: ${colorSet('backgroundSecondary')};

    margin-right: ${space(-6)};
    margin-left: ${space(-6)};
    grid-column: 1 / span 6;

    ${mq('m')} {
      margin-right: ${space(-13)};
      margin-left: ${space(-13)};
      grid-column: 3 / span 8;
    }

    ${mq('l')} {
      margin-right: ${space(-16)};
      margin-left: ${space(-16)};
    }
  }

  .downloads__list {
    margin-top: ${space(9)};
    margin-bottom: ${space(5)};
    margin-right: ${space(6)};
    margin-left: ${space(6)};

    ${getGrid({
  default: 6,
  m: 8,
  l: 6
}, false)};

    ${mq('m')} {
      margin-left: ${space(13)};
      margin-right: ${space(13)};
    }

    ${mq('l')} {
      margin-left: ${space(16)};
      margin-right: ${space(16)};
    }
  }

  .downloads__item {
    margin-bottom: ${space(4)};
    grid-column: 1 / span 6;

    ${mq('m')} {
      grid-column: 1 / span 8;
    }

    ${mq('l')} {
      grid-column: 1 / span 6;
    }
  }

  .downloads__link {
    color: ${colorSet('colorSecondary')};
    letter-spacing: 2px;

    span {
      color: ${colorSet('accent')};
    }

    &:hover span {
      color: inherit;
    }
  }
`;

export default StyledDownloads;
