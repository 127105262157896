import styled$1 from 'styled-components';
import { space, getProperty, mq } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledLogoWall = styled$1(Grid$1)`
  position: relative;
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};

  .logoWall__wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '2 / span 10'
})};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .logoWall__client {
    position: relative;
    width: 50%;

    ${mq('m')} {
      width: 33.33%;
    }

    ${mq('l')} {
      width: 20%;
    }

    p {
      text-align: center;
      margin-top: ${space(-2)};
      margin-bottom: 0;
      padding: 0 ${space(2)};
    }
  }

  .logoWall__client-inner {
    position: relative;
    width: 100%;
    height: 180px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 85%;
      max-height: 85%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default StyledLogoWall;
