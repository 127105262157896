import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getGrid } from '../Grid/Grid.js';

const StyledStage = styled$1.div`
  position: relative;
  margin-bottom: ${space(10)};

  ${mq('l')} {
    padding-top: ${space(4)};
  }

  .stage__breadcrumb {
    position: relative;
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${({
  hasMedia
}) => getProperty('grid-row', {
  default: hasMedia ? '2' : '1',
  l: '1'
})};
    ${getTypography.small};
    color: ${colorSet('accent')};
    margin-top: ${({
  hasMedia
}) => !hasMedia ? space(15) : space(5)};
    margin-bottom: ${space(10)};

    span {
      margin-left: ${space(2)};
    }
  }

  .stage__breadcrumbLink {
    border-bottom: none;

    &::after {
      content: ' ';
      display: inline-block;
      width: 1px;
      height: 11px;
      background-color: ${colorSet('accent')};
      margin-left: ${space(2)};
    }
    ${getTypography.small};
    color: ${colorSet('color')};
    margin-left: ${space(2)};

    ${mq('l')} {
      color: ${({
  hasMedia
}) => !hasMedia ? colorSet('color') : colorSet('background')};
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .stage__image {
    position: relative;
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${getProperty('grid-row', {
  l: '1 / span 2'
})};
    
    margin-right: ${space(-6)};
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-right: ${space(-13)};
      margin-left: ${space(-13)};
      margin-bottom: ${space(10)};
    }

    ${mq('l')} {
      margin-right: ${space(-16)};
      margin-left: ${space(-16)};
    }

    ${mq('xl')} {
      width: 100vw;
      left: calc((1440px - 100vw) / 2);
    }
  }

  .stage__image-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.25);
  }

  .stage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 10',
  l: '2 / span 8'
})};
    ${({
  hasMedia
}) => getProperty('grid-row', {
  l: hasMedia ? '1 / span 2' : '3'
})};
    align-self: center;
    margin-bottom: ${space(10)};
    color: ${colorSet('accent')};
  }

  .stage__news-format {
    color: ${colorSet('color')};
    margin-bottom: ${space(1)};

    ${mq('l')} {
      color: ${({
  hasMedia
}) => !hasMedia ? colorSet('color') : colorSet('background')};
    }
  }

  .stage__info {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 8'
})};
    ${getProperty('grid-row', {
  l: '4 / auto'
})};
  }

  .stage__tags {
    margin-bottom: ${space(2)};
  }

  .stage__subline {
    position: relative;
  }

  .stage__meta {
    ${getGrid({
  default: 6,
  m: 8,
  l: 6
}, false)}
    margin-top: ${space(8)};
    align-items: center;
  }

  .stage__author-image-wrapper {
    ${getProperty('grid-column', {
  default: '1 / span 3',
  m: '1 / span 2'
})};
  }

  .stage__author-image {
    border-radius: 50%;
    overflow: hidden;
  }

  .stage__meta-aside {
    margin-top: ${space(4)};

    ${mq('l')} {
      margin-top: 0;
    }

    ${({
  authorUser
}) => authorUser && authorUser.image ? getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 3',
  l: '3 / span 2'
}) : getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5',
  l: '1 / span 4'
})};
  }

  .stage__author {
    position: relative;
    font-weight: bold;
    letter-spacing: 2px;  
  }

  .stage__post-date {
    display: block;
    position: relative;
    ${getTypography.small};
    margin-top: ${space(1)};

    &:first-of-type {
      display: inline;
    }
  }

  .stage__meta-entry {
    ${getTypography.small};
    margin-left: ${space(1)};
    border-left: 1px solid ${colorSet('accent')};
    padding-left: ${space(1)};
  }

  img,
  video {
    width: 100%;
    object-fit: cover;
  }

  .stage__excerpt {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 8'
})};
    margin-top: ${space(10)};

    ${mq('l')} {
      grid-row: ${props => props.hasMedia ? '6' : '5'};
    }

    p {
      ${getTypography.copyIntro};
    }
  }
`;

export default StyledStage;
