import styled$1 from 'styled-components';
import { space, getProperty, mq } from '@ccg/core';
import Grid$1 from '../Grid/Grid.js';

const StyledTeamList = styled$1(Grid$1)`
  margin-top: ${space(10)};
  margin-bottom: ${space(10)};
  row-gap: ${space(10)};

  .teamList__member {
    ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 4'
})};
    text-align: center;
    padding: 0 15%;

    ${mq('s')} {
      padding: 0 25%;
    }

    ${mq('m')} {
      padding: 0 15%;
    }
  }

  .teamList__member-image {
    margin-bottom: ${space(4)};

    img {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
  }

  .teamList__link {
    ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 12'
})};
    margin: 0 auto;

    a {
      display: inline-block;
    }
  }
`;

export default StyledTeamList;
