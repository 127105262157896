const modular = {
  values: {
    size: 0.5,
    form: 0.5,
    volume: 0.5,
    gravity: 0.5,
    acceleration: 0.54
  },
  ranges: {
    fontFamily: [{
      accent: '"Playfair Display", serif',
      accentItalic: '"Playfair Display Italic", serif',
      default: 'Helvetica Neue, sans-serif'
    }, {
      accent: '"Playfair Display", serif',
      accentItalic: '"Playfair Display Italic", serif',
      default: 'Helvetica Neue, sans-serif'
    }, {
      accent: '"Playfair Display", serif',
      accentItalic: '"Playfair Display Italic", serif',
      default: 'Helvetica Neue, sans-serif'
    }, {
      accent: '"Playfair Display", serif',
      accentItalic: '"Playfair Display Italic", serif',
      default: 'Helvetica Neue, sans-serif'
    }],
    spacingBase: [4],
    transitionDuration: ['0.8s'],
    cubicBezier: ['0.54, 0.0, 0.54, 1', '1, 0.0, 1, 1']
  }
};

export { modular };
