import styled$1, { css } from 'styled-components';
import { Text, space, color, colorSet, mq } from '@ccg/core';
import '@4rk/modular-system';
import { getAccentTopLine, getTypography, fontFamily } from '../../styles/typography.js';
import { getTextColumns } from './helper/getGridColumns.js';
import { getGrid } from '../../components/Grid/Grid.js';

const StyledText = styled$1(Text)`
  position: relative;
  ${props => props.hasAccent && getAccentTopLine}

  h2,
  h3 {
    font-weight: normal;
    margin: 0 0 ${space(6)};
    ${getTypography.sublineBig}
  }

  h4 {
    display: inline-block;
    font-weight: bold;
    margin: 0 0 ${space(6)};
    color: ${color('bright')};
    ${getTypography.subline}
  }

  h2,
  h3,
  h4 {
    .text__highlight {
      ${fontFamily('accent')};
    }
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 ${space(6)};
  }

  li {
    list-style-type: none;
    position: relative;
    margin: 0 0 0 ${space(5)};
    ${getTypography.copy}
  }

  ul > li:before {
    position: absolute;
    content: '';
    background: ${colorSet('accent')};
    top: 12px;
    left: ${space(-5)};
    width: 6px;
    height: 6px;
  }

  ul ul,
  ol ul {
    margin: ${space(2)} 0;

    & > li:before {
      top: 14px;
      width: 4px;
      height: 4px;
    }
  }

  ol {
    counter-reset: section;

    & > li::before {
      position: absolute;
      top: 0;
      left: ${space(-5)};
      color: ${colorSet('accent')};
      counter-increment: section;
      content: counters(section, '.');
    }
  }

  ol ol,
  ul ol {
    counter-reset: section;
    margin: ${space(2)} 0;

    & > li::before {
      counter-increment: section;
      content: counter(section, lower-alpha) '. ';
    }
  }

  p {
    margin: 0 0 ${space(6)};
    ${getTypography.copy}

    &:last-child {
      margin-bottom: 0;
      padding-bottom: ${space(1)};
    }
  }

  a {
    position: relative;
    color: ${colorSet('accent')};
    letter-spacing: ${space(0.5)};
    padding-bottom: ${space(0.5)};
    border-bottom: 2px solid ${colorSet('accent')};
    transition: padding 100ms;
    margin: 0 2px 0 0;

    .text__link-icon-wrapper {
      display: none;
    }

    &[target='_blank'],
    &[href^='mailto:'],
    &[href^='tel:'] {
      .text__link-icon-wrapper {
        display: inline;
        white-space: nowrap;
        position: relative;
      }

      .text__link-icon {
        position: absolute;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='16'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FF005F' d='M3 0l3 5H0z'/%3E%3Cpath stroke='%23FF005F' stroke-linecap='square' stroke-width='2' d='M3 15V5'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 6px 16px;
        width: 6px;
        height: 16px;
        vertical-align: middle;
        bottom: -4px;
        transform: translateX(-4px);
        transition: transform 100ms;
      }

      &:hover .text__link-icon {
        transform: translateX(-4px) translateY(-1px);
      }
    }

    &:hover {
      padding-bottom: ${space(0.25)};
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  .highlight {
    ${getTypography.headline};
  }

  .highlight-small {
    ${getTypography.subline}
  }

  .info-paragraph {
    ${getTypography.small};
  }

  ${props => props.hasGrid && css(["", " margin-top:", ";margin-bottom:", ";", "{margin-top:0;margin-bottom:0;}h2,h3,h4,h5,h6,p,ul,ol{", "}"], getGrid(), space(6), space(6), mq('m'), getTextColumns)}
`;

export default StyledText;
