import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';

const StyledMainStage = styled$1.div`
  .mainStage__grid {
    margin-bottom: ${space(11)};
    padding-top: ${space(12)};
    position: relative;

    ${mq('m')} {
      margin-bottom: ${space(16)};
    }

    ${mq('l')} {
      padding-top: 0;
    }
  }

  .mainStage__breadcrumb {
    position: relative;
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    ${({
  hasMedia
}) => getProperty('grid-row', {
  default: hasMedia ? '2' : '1',
  m: '1'
})};
    ${getTypography.small};
    color: ${colorSet('accent')};
    margin-top: ${({
  hasMedia
}) => !hasMedia ? space(15) : space(5)};

    span {
      margin-left: ${space(2)};
    }
  }

  .mainStage__breadcrumbLink {
    border-bottom: none;

    &::after {
      content: ' ';
      display: inline-block;
      width: 1px;
      height: 11px;
      background-color: ${colorSet('accent')};
      margin-left: ${space(2)};
    }
    ${getTypography.small};
    color: ${colorSet('color')};
    margin-left: ${space(2)};

    &:first-child {
      margin-left: 0;
    }

    ${mq('m')} {
      color: ${({
  hasMedia
}) => !hasMedia ? colorSet('color') : colorSet('background')};
    }
  }

  .mainStage__media {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
})};
    grid-row: 1;
    position: relative;
    margin-right: ${space(-6)};
    margin-left: ${space(-6)};

    ${mq('m')} {
      margin-right: ${space(-13)};
      margin-left: ${space(-13)};
      margin-bottom: ${space(10)};
    }

    ${mq('l')} {
      margin-right: ${space(-16)};
      margin-left: ${space(-16)};
    }

    ${mq('xl')} {
      width: 100vw;
      left: calc((1440px - 100vw) / 2);
    }
  }

  .mainStage__media-ratio {
    height: calc(100vh - ${space(12)});
    position: relative;
    box-sizing: border-box;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
    }

    img,
    video {
      object-fit: cover;
      height: 100%;
      transition: transform 0.2s;
    }

    > * {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  .mainStage__scroll {
    position: absolute;
    left: 50%;
    bottom: ${space(4)};
    display: inline-block;
    width: 1.875rem;
    height: 3.125rem;
    border-radius: 10rem;
    border: 2px solid #fff;
    transform: translateX(-50%);

    ${mq('m')} {
      bottom: ${space(6)};
    }

    &:after {
      transform: translate(-50%, 0);
      content: '';
      position: absolute;
      display: block;
      top: 25%;
      left: 50%;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
      animation: mouse 2s linear infinite;
    }
  }

  .mainStage__headline {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12',
  l: '1 / span 8'
})};
    color: ${colorSet('accent')};
    grid-row: 1;
    margin-top: 35%;

    &:first-child {
      margin-top: ${space(10)};
    }

    h1 {
      margin-top: ${space(7)};
      transform: scale(0.8);
      transform-origin: top left;

      ${mq('m')} {
        margin-top: 0;
        transform: scale(1);
      }
    }
  }

  .mainStage__breadcrumb + .mainStage__headline h1 {
    margin-top: 0;
  }

  .mainStage__subline {
    margin-top: ${space(10)};
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 4'
})};

    ${mq('m')} {
      grid-row: 3;
      margin-top: ${space(5)};
    }
  }

  .mainStage__excerpt {
    ${getProperty('grid-column', {
  default: '1 / span 6'
})};
    margin-top: ${space(10)};

    ${mq('m')} {
      grid-row: 4;
    }

    p {
      ${getTypography.copyIntro};
    }
  }

  .mainStage__link:hover {
    .mainStage__media img {
      transform: scale(1.05);
    }

    .mainStage__headline h1 {
      transform: scale(1.02);
    }
  }

  @keyframes mouse {
    0% {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    100% {
      opacity: 0;
      transform: translate(-50%, 20px);
    }
  }
`;

export default StyledMainStage;
