import styled$1, { css } from 'styled-components';
import { colorSet, space } from '@ccg/core';

const StyledTeaserContainer = styled$1.div`
  position: relative;
  background: ${colorSet('background')};
  margin: ${space(12)} 0 ${space(20)};

  .teaserOverview + & {
    padding-top: 0;
  }

  .teaserContainer__spinner {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .teaserContainer__no-results {
    grid-column: 1 / -1;

    button {
      margin: ${space(8)} 0;
    }
  }

  .teaserContainer__wrapper {
    position: relative;
    row-gap: ${space(10)};

    ${props => props.filtering && css(["opacity:0.3;"])}
  }

  .teaserContainer__load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${space(12)} ${space(4)};
  }
`;

export default StyledTeaserContainer;
