import styled$1 from 'styled-components';
import { space, mq, getProperty, colorSet, zIndex, color } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import Grid$1 from '../Grid/Grid.js';

const StyledGallery = styled$1(Grid$1)`
  margin-top: ${space(12)};
  margin-bottom: ${space(12)};
  position: relative;

  ${mq('m', 'max')} {
    grid-gap: ${space(3)};
    row-gap: 0;
  }

  .gallery__meta,
  .gallery__overview-button {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '3 / span 8',
  l: '4 / span 6'
})};
  }

  .gallery__overview-button button {
    ${getTypography.copy};
    margin-top: ${space(8)};
    line-height: 1;
    transition: color 100ms;

    > div {
      display: inline-block;
      transition: margin-left 100ms;
    }

    &:active,
    &:focus,
    &:hover {
      color: ${colorSet('accent')};

      > div {
        margin-left: 2px;
      }
    }
  }

  .gallery__row {
    ${getProperty('grid-column-end', {
  default: 'span 2',
  m: 'span 4',
  l: 'span 4'
})};
  }

  .gallery__image {
    margin: ${space(3)} 0;

    ${mq('m')} {
      margin: 15px 0;
    }

    ${mq('l')} {
      margin: 30px 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .gallery__image-ratio {
    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .gallery__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .gallery__image-caption {
    position: relative;
    z-index: ${zIndex('top')};
    display: none;
    margin-bottom: ${space(4)};
    padding: 0 ${space(2)};
    color: ${color('light')};
  }

  .gallery__button {
    width: 100%;

    svg {
      position: relative;
      z-index: ${zIndex('top')};
      display: none;
      margin: 0 auto;
      fill: ${color('light')};
    }

    &:active,
    &:focus,
    &:hover {
      svg,
      .gallery__image-caption {
        display: block;
      }

      .gallery__image-caption {
        ${mq('m', 'max')} {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(60, 0, 156, 0.7);
      }
    }
  }
`;

export default StyledGallery;
