import styled$1, { css } from 'styled-components';
import { colorSet, mq } from '@ccg/core';
import { getOuterGridContainer, getInnerGridContainer } from '../Grid/Grid.js';

const StyledMainContainer = styled$1.main`
  ${getOuterGridContainer()}
  position: relative;
  overflow: hidden;
  background: ${colorSet('background')};
  color: ${colorSet('color')};
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.77, 1), background 0.1s 0.8s ease-in-out,
    color 0.1s 0.8s ease-in-out;
  min-height: 100vh;

  ${({
  navigationIsOpen
}) => navigationIsOpen && css(["transform:translate(0,125vh);pointer-events:none;", "{transform:none;pointer-events:all;}"], mq('l'))}
  .mainContainer__inner {
    ${getInnerGridContainer()}
    position: relative;
  }
`;

export default StyledMainContainer;
