import styled$1, { css } from 'styled-components';
import { getProperty, space, mq, colorSet } from '@ccg/core';
import '@4rk/modular-system';
import { getTypography } from '../../styles/typography.js';
import { getTransition } from '../../styles/transitions.js';
import { getGrid } from '../Grid/Grid.js';

const StyledTeaser = styled$1.div`
  ${getTransition}
  ${getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 6'
})};
  align-self: start;

  .teaser__link {
    ${getGrid({
  default: 6
}, false)};
    height: 100%;
  }

  .teaser__media {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
    margin-bottom: ${space(5)};
    grid-row: 1;

    img,
    video {
      display: block;
      width: 100%;
      overflow: hidden;
      transition: transform 0.2s;
      object-fit: cover;
    }
  }

  ${mq('m', 'max')} {
    &:not(:first-child) {
      margin-top: ${space(10)};
    }
  }

  ${mq('m')} {
    &:not(:first-child):not(:nth-child(2)) {
      margin-top: ${space(10)};
    }
  }

  .teaser__content {
    ${getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 5'
})};
    position: relative;
    align-self: center;
    grid-row: 2;
  }

  .teaser__topic {
    ${getTypography.small};
    margin-bottom: 2px;
  }

  .teaser__headline {
    position: relative;
    margin-bottom: ${space(4)};
    color: ${colorSet('accent')};
    display: inline-block;
    word-break: break-word;
    transition: transform 0.4s;
    will-change: transform;

    ${mq('m')} {
      ${getTypography.subheadline};
    }

    ${mq('l')} {
      ${getTypography.intro};
    }
  }

  .teaser__media,
  .teaser__subline,
  .teaser__cta {
    padding-left: 17%;

    ${mq('m')} {
      padding-left: 10%;
    }

    ${mq('l')} {
      padding-left: 21%;
    }
  }

  .teaser__cta span {
    position: relative;
    display: inline-block;
    ${getTypography.copy};
    letter-spacing: 2px;
    margin-top: ${space(4)};
    padding-bottom: ${space(1)};

    &:after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: ${colorSet('accent')};
      transition: transform 0.1s ease-in-out;
    }
  }

  ${({
  teaserType,
  hasMedia
}) => {
  switch (teaserType) {
    case '/case':
      return css(["align-self:center;", "{&:not(:first-child){margin-top:0;}}", "{&:not(:first-child):not(:nth-child(2)){margin-top:0;}}.teaser__media{", ";margin-bottom:0;padding-left:0;}.teaser__ratio div{&:after{content:'';position:absolute;top:0;left:0;width:100%;height:100%;background-color:rgba(2,0,0,0.1);}}.teaser__topic{color:", ";}.teaser__headline{margin-bottom:0;}.teaser__content{grid-column:1 / span 6;grid-row:1;", ";}"], mq('m', 'max'), mq('m'), getProperty('grid-column', {
        default: '1 / span 6',
        m: '1 / span 6'
      }), hasMedia && colorSet('background'), hasMedia && css(["align-self:end;margin-bottom:", ";padding:0 ", ";", "{margin-bottom:", ";padding:0 ", ";}"], space(5), space(5), mq('l'), space(15), space(10)));

    default:
      return null;
  }
}};

  ${({
  featured
}) => featured && css(["", ";.teaser__link{", ";}.teaser__media{", ";}.teaser__ratio div{", "{padding-bottom:0;height:646px;}}"], getProperty('grid-column-end', {
  default: 'span 6',
  m: 'span 12'
}), getGrid({
  default: 6,
  m: 12
}, false), getProperty('grid-column', {
  default: '1 / span 6',
  m: '1 / span 12'
}), mq('l'))};

  .teaser__link:hover {
    .teaser__media img {
      transform: scale(1.05);
    }

    .teaser__headline {
      transform: scale(1.02);
    }

    .teaser__cta span:after {
      transform: translateY(${space(-0.5)});
    }
  }
`;

export default StyledTeaser;
